<template>
    <zj-scroll-list identity="zSoldier" :titleList="titleList" :dataList="soldierList" :limit="5" :loop="true" @mobile="onMobile" @video="onVideo" @file="onFile" @location="onLocation" />
</template>

<script>
import ajax from '@/ajax.js'
import ZjScrollList from 'zj-scroll-list'
export default {
    name: 'ComSoldiers',
    components: {
        ZjScrollList,
    },
    data(){
        return {
            titleList: [
                {flex: 5, title: '姓名'},
                {flex: 5, title: '联系电话'},
                {flex: 4, title: '操作'},
            ],
            soldiers: [
                {
                    id: '1',
                    title: '我是一个兵1',
                    phone: '13875102770',
                    lng: 120.10,
                    lat: 30.20,
                    address: '中国广东省韶关市仁化县丹霞大道118号',
                    is_active: false,
                    is_hover: false,
                    is_open: false
                },
            ],
        }
    },
    computed:{
        soldierList(){
            let list = [];
            for(const it of this.soldiers){
                // console.log("update:", it )
                list.push({
                    "key": "key1",
                    "raw": it,
                    "row": [
                        {
                            "icon": require("@/static/icons/icon-soldier.svg"),
                            "icon": require("@/static/icons/icon-soldier.svg"),
                            "icon_color": "green",
                            "text": it.title || it.name,
                            // "text_color": "blue",
                        },
                        { "text": it.soldier_number || it.phone, },
                        {
                            list: [
                                {
                                    "icon": require("@/static/icons/icon-phone.svg"),
                                    "icon_color": "none",
                                    "tip": "拨打电话",
                                    "emit": "mobile",
                                },
                                {
                                    "icon": require("@/static/icons/icon-carema-fill.svg"),
                                    "icon_color": "none",
                                    "tip": "视频会议",
                                    "emit": "video",
                                },
                                {
                                    "icon": require("@/static/icons/icon-file-fill.svg"),
                                    "icon_color": "none",
                                    "tip": "文件",
                                    "emit": "file",
                                },
                                {
                                    "icon": require("@/static/icons/icon-local.svg"),
                                    "icon_color": "none",
                                    "tip": "定位坐标",
                                    "emit": "location",
                                },
                            ],
                        },
                    ]
                });
            }
            return list;
        },
    },
    created(){
        this.download();
    },
    methods:{
        download(){
            ajax({url: '/api/soldier',}).then(res => {
                // console.log("download:", res);
                this.soldiers = res.data
            })
        },
        update(){
            
        },
        onMobile(e){
            this.$store.dispatch('showWindow', 'comDialUp')
            const phone = e.row[1].text;
            this.$store.dispatch('setWindowConfig', {window: 'comDialUp', config: {phone: phone}})
        },
        onVideo(e){
            console.log(e)
            this.$store.dispatch('showWindow', 'comVideoConference');
            
            if(e.raw){
                let list = this.$store.state.modWindows['comVideoConference']['config']['members']
                list = JSON.parse(JSON.stringify(list))
                list.push(e.raw)
                this.$store.dispatch('setWindowConfig', {
                    window: 'comVideoConference',
                    config: {members: list},
                })
            }
        },
        onFile($event){
            console.log("onFile: ", $event)
            this.$store.dispatch('showWindow', 'comFiles');
            this.$store.dispatch('setWindowConfig', {window: 'comFiles', 
                config: {
                    surname: $event.row[0].text, 
                    phone: $event.row[1].text, 
                    short_number: $event.row[1].short_number},
                },
            );
        },
        onLocation(e){
            // console.log("onLocation: ", e);
            const marker = e.raw;
            // console.log("onLocation: ", marker.lat, marker.lng);
            this.$store.dispatch('setMapCenter', {latitude: marker.lat, longitude: marker.lng})
            this.$store.dispatch('setMarkerValue', {group: 'soldiers', id: marker.add_id, key: 'is_open', value: true})
            this.$store.dispatch('setMarkerValue', {group: 'soldiers', id: marker.add_id, key: 'is_active', value: true})
            
            // this.$store.dispatch('setMapZoom', zoom)
        },
    },
    watch:{
        soldiers: {
            handler(val,old){
                this.update();
            },
            deep: true,
        },
    }
}
</script>